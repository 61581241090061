<template>
  <label class="checkbox-container g-8 the-same-gap full-width">
    <input
        @change="handleChange($event)"
        :checked="modelValue || checked"
        :name="name"
        :id="id"
        type="checkbox"
    />
    <span class="checkmark flex-center" style="display: flex">
      <CheckIcon stroke="white" width="14px" />
    </span>
    <span class="text full-width">{{ text }}</span>
  </label>
</template>

<script>
import CheckIcon from "~/components/icons/CheckIcon.vue";

export default {
  components: {CheckIcon},
  props: {
    text: String,
    id: String,
    name: String,
    checked: Boolean,
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  methods: {
    handleChange(event) {
      this.$emit('update:modelValue', event.target.checked);
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox-container {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
  border: 1px solid $black;
  border-radius: 5px;

  &:hover {
    background-color: $grey;
  }
}

svg {
  display: none;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: $black;

  svg {
    display: block;
  }
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark::after {
  display: block;
}
</style>
